var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeader pt-2"
  }, [_vm._v(_vm._s(_vm.$t("profile.profilePage.economy")))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.bankAccountNo || _vm.$t('profile.signupPage.bankAccountMissing'),
      "headline": _vm.$t('profile.signupPage.bankAccountNumber'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": _vm.$t('profile.signupPage.bankAccountNumber'),
      "hint": _vm.$t('profile.signupPage.bankAccountMessage'),
      "rules": _vm.validateOptionalAccountNumber,
      "outlined": ""
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.bankAccountNo,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "bankAccountNo", $$v);
      },
      expression: "customerValues.bankAccountNo"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }