
import { api } from "@/api/api";
import { ApiGetCustomerDto, ApiUpdateCustomerPersonDto } from "@/api/generated/Api";
import DisplayEditAddress from "@/components/profile/DisplayEditAddress.vue";
import DisplayEditOptions from "@/components/profile/DisplayEditOptions.vue";
import DisplayEditEconomy from "@/components/profile/DisplayEditEconomy.vue";
import DisplayProfile from "@/components/profile/DisplayEditProfile.vue";
import DisplayEditCredentials from "@/components/profile/DisplayEditCredentials.vue";
import TheProfileHeader from "@/components/profile/TheProfileHeader.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { flattenObject } from "@/shared/helpers/objectHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "profile",
  components: {
    TheProfileHeader,
    BaseBreadCrumbs,
    BaseLayout,
    DisplayProfile,
    DisplayEditAddress,
    DisplayEditOptions,
    DisplayEditCredentials,
    DisplayEditEconomy,
  },
  setup(props, context) {
    const customerFetch = ref<ApiGetCustomerDto>();
    const customer = ref<ApiUpdateCustomerPersonDto>();
    const isEditingProfile = ref(false);

    const submitChanges = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = !!getValidatableRef(context.refs.form)?.validate();
        if (isValid && customer.value) {
          await api.minside.updateCustomerPersonSelfAsync(customer.value);
          isEditingProfile.value = false;
        }
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const customerResponse = (await api.minside.getCustomerPersonCurrentAsync()).data;
        customerFetch.value = customerResponse;
        customer.value = {
          ...flattenObject(customerResponse),
          // receiveNewsletter: customerResponse.customer.receiveNewsletter,
          // Since we removed the option to edit this, AOF wants this to be default true
          receiveNewsletter: true,
        };
      });
    });

    return {
      isMobile,
      customer,
      customerFetch,
      isEditingProfile,
      submitChanges,
      editInternalProfile: () => (isEditingProfile.value = true),
      updateInternalProfile: (updatedCustomer: ApiUpdateCustomerPersonDto) => (customer.value = updatedCustomer),
    };
  },
});
