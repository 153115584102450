var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "displayCardActions": _vm.isEditingProfile,
      "displayToolbar": !_vm.isMobile
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Profil")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [!_vm.isEditingProfile ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.editInternalProfile
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(_vm._s(_vm.$t("generic.buttons.editProfile")) + " ")], 1) : _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              _vm.isEditingProfile = false;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-close")]), _vm._v(_vm._s(_vm.$t("generic.buttons.stopEditProfile")) + " ")], 1)];
      },
      proxy: true
    }, {
      key: "cardActions",
      fn: function () {
        return [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.isEditingProfile = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("generic.buttons.cancel")) + " ")]), _c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.submitChanges
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("generic.buttons.save")) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.customer ? _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.customer ? _c('DisplayEditCredentials', {
    attrs: {
      "email": _vm.customerFetch && _vm.customerFetch.email || ''
    }
  }) : _vm._e(), _vm.customer ? _c('DisplayProfile', {
    attrs: {
      "isEditingProfile": _vm.isEditingProfile,
      "customer": _vm.customer
    },
    on: {
      "updateInternalProfile": _vm.updateInternalProfile
    }
  }) : _vm._e(), _vm.customer ? _c('DisplayEditAddress', {
    attrs: {
      "isEditingProfile": _vm.isEditingProfile,
      "customer": _vm.customer
    },
    on: {
      "updateInternalProfile": _vm.updateInternalProfile
    }
  }) : _vm._e(), _vm.customer ? _c('DisplayEditEconomy', {
    attrs: {
      "isEditingProfile": _vm.isEditingProfile,
      "customer": _vm.customer
    },
    on: {
      "updateInternalProfile": _vm.updateInternalProfile
    }
  }) : _vm._e(), _vm.customer ? _c('DisplayEditOptions', {
    attrs: {
      "isEditingProfile": _vm.isEditingProfile,
      "customer": _vm.customer
    },
    on: {
      "updateInternalProfile": _vm.updateInternalProfile
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }