
import { ApiUpdateCustomerPersonDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { validateNotEmpty, validateOptionalAccountNumber } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import BaseIconCard from "../shared/card/BaseIconCard.vue";
import GooglePlacesAddressFieldGroup from "../shared/GooglePlacesAddressFieldGroup.vue";

export default defineComponent({
  components: { GooglePlacesAddressFieldGroup, BaseIconCard },
  name: "DisplayEditEconomy",
  props: {
    customer: {
      type: Object as PropType<ApiUpdateCustomerPersonDto>,
      required: true,
    },
    isEditingProfile: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["updateInternalProfile"],
  setup(props, { emit }) {
    const customerValues = useAutoDeepCloneProp<ApiUpdateCustomerPersonDto>(props, "customer");

    const emitChanges = () => emit("updateInternalProfile", customerValues.value);

    return {
      customerValues,
      emitChanges,
      validateNotEmpty,
      isMobile,
      validateOptionalAccountNumber,
    };
  },
});
