var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.emailModalData.showModal ? _c('BaseModal', [_c('TheContactInformationDetailsModal', {
    attrs: {
      "headline": _vm.emailModalData.modalHeadline,
      "modalType": _vm.emailModalData.modalType,
      "currentContactInformation": _vm.email
    },
    on: {
      "close": function ($event) {
        _vm.emailModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeader pt-2"
  }, [_vm._v(_vm._s(_vm.$t("profile.profilePage.credentialsHeader")))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.email || '',
      "headline": _vm.$t('profile.signupPage.emailAndUsername').toString(),
      "dataCy": "editEmail",
      "prependIcon": "mdi-pencil",
      "link": false,
      "captionAbove": "",
      "dense": ""
    },
    on: {
      "clickedPrependIcon": _vm.openEditEmailModal
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": "********",
      "headline": _vm.$t('profile.profilePage.password').toString(),
      "link": false,
      "prependIcon": "mdi-pencil",
      "captionAbove": "",
      "dense": ""
    },
    on: {
      "clickedPrependIcon": _vm.updatePassword
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }