
import { mapGetters } from "vuex";
import { getFullName } from "@/shared/helpers/customerHelpers";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheProfileHeader",
  props: {
    userType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("customer", ["getCust"]),
    fullName() {
      return getFullName((this as any).getCust);
    },
  },
});
