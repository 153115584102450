var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeader pt-2"
  }, [_vm._v(_vm._s(_vm.$t("profile.profilePage.addressHeader")))])]), !_vm.isEditingProfile ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.postAddress1 || 'Adresse mangler',
      "headline": _vm.$t('profile.signupPage.streetName'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1) : _vm._e(), !_vm.isEditingProfile ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.postZip || 'Postnr mangler',
      "headline": _vm.$t('profile.signupPage.postCode'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1) : _vm._e(), !_vm.isEditingProfile ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.postCity || 'Poststed mangler',
      "headline": _vm.$t('profile.signupPage.postLocation'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1) : _vm._e(), !_vm.isEditingProfile ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.postCountry ? _vm.getCountryName(_vm.customerValues.postCountry) : 'Land mangler',
      "headline": _vm.$t('profile.signupPage.country'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1) : _vm._e(), _vm.isEditingProfile ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('GooglePlacesAddressFieldGroup', {
    ref: "validateFormRef",
    attrs: {
      "exisitingAddress": _vm.addressValues
    },
    on: {
      "updateAddress": _vm.updateCustomerAddress
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }