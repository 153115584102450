var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "sub-title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    },
    attrs: {
      "data-cy": "profileSubHeader"
    }
  }, [_vm.userType === 'P' ? _c('p', [_vm._v("Du er registrert som: student")]) : _vm._e(), _vm.userType === 'T' ? _c('p', [_vm._v("Du er registrert som: lærer")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }