
import { updatePasswordInB2C } from "@/authService";
import i18n from "@/plugins/i18n";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { defineComponent, ref } from "@vue/composition-api";
import BaseIconCard from "../shared/card/BaseIconCard.vue";
import BaseBirthDateInput from "../shared/date/BaseBirthDateInput.vue";
import BaseModal from "../shared/modal/BaseModal.vue";
import TheContactInformationDetailsModal from "./TheContactInformationDetailsModal.vue";

export default defineComponent({
  components: { BaseIconCard, BaseModal, TheContactInformationDetailsModal, BaseBirthDateInput },
  name: "DisplayEditCredentials",
  props: {
    email: {
      type: String,
      required: false,
    },
  },
  setup() {
    const emailModalData = ref(getInitialModalData());

    const updatePassword = () => {
      updatePasswordInB2C();
    };

    return {
      emailModalData,
      updatePassword,
      openEditEmailModal: useOpenModal(ModalType.Edit, i18n.t("profile.signupPage.emailAndUsername"), emailModalData),
    };
  },
});
