var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "hideCancelAndSaveButton": !_vm.displayVerificationCodeField,
      "submitButtonText": "Verifiser"
    },
    on: {
      "submit": _vm.updateContactInformation,
      "close": function ($event) {
        return _vm.$emit('close');
      }
    },
    model: {
      value: _vm.formIsValid,
      callback: function ($$v) {
        _vm.formIsValid = $$v;
      },
      expression: "formIsValid"
    }
  }, [_c('v-form', {
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.formIsValid,
      callback: function ($$v) {
        _vm.formIsValid = $$v;
      },
      expression: "formIsValid"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "emailInputField",
      "rules": [_vm.validateNotEmpty, _vm.validateIsEmail],
      "label": "Rediger epostadresse",
      "disabled": _vm.displayVerificationCodeField,
      "persistent-hint": "",
      "hint": "Oppdatering av e-post gjennomføres med en gang, men vises ikke på min side før om 12 timer"
    },
    model: {
      value: _vm.contactInformation,
      callback: function ($$v) {
        _vm.contactInformation = $$v;
      },
      expression: "contactInformation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.formIsValid,
      "color": "primary",
      "data-cy": "sendCodeButton"
    },
    on: {
      "click": _vm.sendEmailVerificationCode
    }
  }, [_vm._v(_vm._s(_vm.displayVerificationCodeField ? "Send kode på nytt" : "Send verifikasjonskode"))])], 1)], 1), _vm.displayVerificationCodeField ? _c('v-row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Vennligst fyll ut verifikasjonskoden som ble sendt til "), _c('b', [_vm._v(_vm._s(_vm.contactInformation))]), _c('v-otp-input', {
    attrs: {
      "data-cy": "verificationInputField",
      "type": "number",
      "length": "6"
    },
    model: {
      value: _vm.verificationCode,
      callback: function ($$v) {
        _vm.verificationCode = $$v;
      },
      expression: "verificationCode"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }