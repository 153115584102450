
import { ApiUpdateCustomerPersonDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { isNumber } from "@/shared/helpers/formattingHelpers";
import { validateNotEmpty, validNationalIdNumber } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { isWithinInterval, parseISO } from "date-fns";
import BaseIconCard from "../shared/card/BaseIconCard.vue";
import BaseBirthDateInput from "../shared/date/BaseBirthDateInput.vue";

export default defineComponent({
  components: { BaseIconCard, BaseBirthDateInput },
  name: "DisplayEditProfile",
  props: {
    customer: {
      type: Object as PropType<ApiUpdateCustomerPersonDto>,
      required: true,
    },
    isEditingProfile: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["updateInternalProfile"],
  setup(props, { emit }) {
    const customerValues = useAutoDeepCloneProp<ApiUpdateCustomerPersonDto>(props, "customer");

    const dateOfBirth = computed(() =>
      props.customer.birthDate &&
      isWithinInterval(parseISO(props.customer.birthDate), {
        start: new Date(1900, 1, 1),
        end: new Date(),
      })
        ? formatDate(props.customer.birthDate)
        : props.customer.birthYear?.toString().length === 4
        ? props.customer.birthYear
        : "Ingen fødselsdato"
    );

    const updateBirthDate = (birthDate: string) => {
      customerValues.value && (customerValues.value.birthDate = birthDate);
      emitChanges();
    };

    const updateBirthYear = (year: string) => {
      customerValues.value && (customerValues.value.birthYear = +year);
      emitChanges();
    };

    const emitChanges = () =>
      emit("updateInternalProfile", { ...customerValues.value, gender: customerValues.value.gender });

    return {
      dateOfBirth,
      customerValues,
      isNumber,
      validateNotEmpty,
      validNationalIdNumber,
      emitChanges,
      updateBirthDate,
      updateBirthYear,
    };
  },
});
