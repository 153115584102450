
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty, validateIsEmail } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TheContactInformationDetailsModal",
  components: { BaseModalForm },
  emits: ["close", "refreshContactInformation"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    currentContactInformation: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const contactInformation = ref<string>(props.currentContactInformation || "");
    const displayVerificationCodeField = ref<boolean>(false);
    const verificationCode = ref<string>("");
    const formIsValid = ref<boolean>(true);

    const sendEmailVerificationCode = async () => {
      // Send API request for verification code here.
      if (contactInformation.value && formIsValid) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          await api.minside
            .generateEmailVerificationCodeAsync({
              emailAddress: contactInformation.value,
            })
            .then((res) => {
              if (res.status === 204) {
                openNotification(store, NotificationItemType.Success, "Verifikasjonskode ble sendt", 5000);
                displayVerificationCodeField.value = true;
              }
            });
        });
      }
    };

    const updateContactInformation = async () => {
      const canSubmit = computed<boolean>(
        () => verificationCode.value.length === 6 && !Number.isNaN(verificationCode.value)
      );
      if (canSubmit) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          await api.minside.updateCustomerPersonEmailSelfAsync({
            emailAddress: contactInformation.value,
            emailVerificationCode: verificationCode.value,
          });
          openNotification(store, NotificationItemType.Success, "Epostadresse ble verifisert og oppdatert", 8000);
          emit("close");
          emit("refreshContactInformation");
        });
      } else {
        openNotification(store, NotificationItemType.Error, "Verifikasjonskode er ikke fullstendig", 8000);
      }
    };

    return {
      formIsValid,
      contactInformation,
      verificationCode,
      validateNotEmpty,
      validateIsEmail,
      updateContactInformation,
      displayVerificationCodeField,
      sendEmailVerificationCode,
    };
  },
});
