var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeader pt-2"
  }, [_vm._v(_vm._s(_vm.$t("profile.profilePage.contactInformationHeader")))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.firstName || _vm.$t('profile.signupPage.firstNameMissing'),
      "headline": _vm.$t('profile.signupPage.firstNameMiddleName'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.validateNotEmpty],
      "label": ((_vm.$t('profile.signupPage.firstNameMiddleName')) + " *"),
      "outlined": ""
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "firstName", $$v);
      },
      expression: "customerValues.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.lastName || _vm.$t('profile.signupPage.lastNameMissing'),
      "headline": _vm.$t('profile.signupPage.lastName'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.validateNotEmpty],
      "label": ((_vm.$t('profile.signupPage.lastName')) + " *"),
      "outlined": ""
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "lastName", $$v);
      },
      expression: "customerValues.lastName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.mobileNumber || _vm.$t('profile.signupPage.mobileNumberMissing'),
      "headline": "Mobilnummer",
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-phone-input', {
    staticClass: "removeDefails",
    attrs: {
      "outlined": "",
      "rules": [_vm.validateNotEmpty],
      "country-icon-mode": "svg",
      "label": ((_vm.$t('profile.signupPage.mobileNumber')) + " *"),
      "countryLabel": ((_vm.$t('profile.signupPage.country')) + " *")
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.mobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "mobileNumber", $$v);
      },
      expression: "customerValues.mobileNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.dateOfBirth,
      "headline": "Fødselsdato",
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('BaseBirthDateInput', {
    attrs: {
      "birthDate": _vm.customerValues.birthDate,
      "birthYear": _vm.customerValues.birthYear && _vm.customerValues.birthYear.toString(),
      "persistent-placeholder": "",
      "outlined": ""
    },
    on: {
      "emitChanges": _vm.updateBirthDate,
      "dateChanged": _vm.updateBirthDate,
      "yearChanged": _vm.updateBirthYear
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.gender === 'M' ? _vm.$t('generic.options.male') : _vm.$t('generic.options.female'),
      "headline": "Kjønn",
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-select', {
    attrs: {
      "outlined": "",
      "items": [{
        label: _vm.$t('generic.options.male'),
        value: 'M'
      }, {
        label: _vm.$t('generic.options.female'),
        value: 'K'
      }],
      "rules": [_vm.validateNotEmpty],
      "label": _vm.$t('profile.signupPage.gender'),
      "item-text": "label",
      "item-value": "value"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.gender,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "gender", $$v);
      },
      expression: "customerValues.gender"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.ssno || _vm.$t('profile.signupPage.socialSecurityNumberMissing'),
      "headline": _vm.$t('profile.signupPage.socialSecurityNumber'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _c('v-text-field', {
    attrs: {
      "rules": [_vm.validNationalIdNumber],
      "label": _vm.$t('profile.signupPage.socialSecurityNumber'),
      "outlined": ""
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      },
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.ssno,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "ssno", $$v);
      },
      expression: "customerValues.ssno"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }