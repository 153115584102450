
import { ApiUpdateCustomerPersonDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { getCountries } from "@/shared/helpers/localeHelpers";
import { CustomerAddress } from "@/shared/interfaces/GooglePlaces.interface";
import { defineComponent, PropType, Ref } from "@vue/composition-api";
import BaseIconCard from "../shared/card/BaseIconCard.vue";
import GooglePlacesAddressFieldGroup from "../shared/GooglePlacesAddressFieldGroup.vue";

export default defineComponent({
  components: { GooglePlacesAddressFieldGroup, BaseIconCard },
  name: "DisplayEditAddress",
  props: {
    customer: {
      type: Object as PropType<ApiUpdateCustomerPersonDto>,
      required: true,
    },
    isEditingProfile: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["updateInternalProfile"],
  setup(props, { emit }) {
    const customerValues: Ref<ApiUpdateCustomerPersonDto> = useAutoDeepCloneProp<ApiUpdateCustomerPersonDto>(
      props,
      "customer"
    );
    const addressValues: CustomerAddress = {
      postAddress1: customerValues.value.postAddress1,
      postZip: customerValues.value.postZip,
      postCity: customerValues.value.postCity,
      postCountry: customerValues.value.postCountry,
    };
    const countryArr = getCountries();

    const getCountryName = (countryCode: string) => {
      const currentCountry = countryArr.find((country) => country.value === countryCode);

      return currentCountry ? currentCountry.label : countryCode;
    };

    const updateCustomerAddress = (address: CustomerAddress) => {
      if (!customerValues.value) {
        return;
      }
      customerValues.value.postAddress1 = address.postAddress1;
      customerValues.value.postCity = address.postCity;
      customerValues.value.postZip = address.postZip;
      customerValues.value.postCountry = address.postCountry;
      emitChanges();
    };

    const emitChanges = () => emit("updateInternalProfile", customerValues.value);

    return {
      customerValues,
      addressValues,
      emitChanges,
      updateCustomerAddress,
      getCountryName,
    };
  },
});
