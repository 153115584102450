var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeader pt-2"
  }, [_vm._v(_vm._s(_vm.$t("profile.profilePage.termsAndConditionsHeader")))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.lastApprovedTermsOfUsage ? _vm.$t('generic.options.yes') : _vm.$t('generic.options.no'),
      "headline": _vm.$t('profile.signupPage.acceptTermsOfUsage'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  }) : _vm._e(), _vm.isEditingProfile ? _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "v1",
      "rules": [_vm.validateNotEmpty],
      "hide-details": "",
      "label": _vm.$t('profile.signupPage.acceptTermsOfUsage')
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.customerValues.lastApprovedTermsOfUsage,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "lastApprovedTermsOfUsage", $$v);
      },
      expression: "customerValues.lastApprovedTermsOfUsage"
    }
  }) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary mt-3",
      "href": "https://www.aof.no/personvernerklaering/",
      "target": "_blank",
      "block": ""
    }
  }, [!_vm.isMobile ? _c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-link")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("profile.signupPage.termsOfUsageBtn")) + " ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }